<template>
    <!--注意： 表单中需要右对齐的才加  class="form-title"-->
    <!--注意： 表单中需要右对齐的才加  class="form-title"-->
    <!--注意： 表单中需要右对齐的才加  class="form-title"-->
    <div>
        <div class="h-handle-bg">
            <div class="h-handle-button">
                <p class="h-return">
                    <el-button type="text" size="small" icon="el-icon-arrow-left" @click="back">返回</el-button>
                </p>
                <div class="h-b">
                    <el-button type="primary" size="small" @click="add" v-right-code="'Gspdrugstopsale:Save'">新增</el-button>
                </div>
                <div class="h-b">
                    <el-button type="primary" size="small" @click="save" v-right-code="'Gspdrugstopsale:Save'">保存</el-button>
                </div>
            </div>
        </div>
        <div>
            <el-form ref="basicForm" :model="dataSource" :rules="basicValidate">
                <el-tabs type="border-card" v-model.trim="activeName">
                    <el-tab-pane label="" name="tabbasic">
                        <el-collapse v-model.trim="collapseItem">
                            <el-collapse-item title="基本信息" name="basicinfo">
                                <el-form-item v-if="!config.isAdd">
                                    <el-col :span="3" class="form-title">单据号：</el-col>
                                    <el-col :span="9">
                                        <el-form-item>
                                            <el-input v-model.trim="dataSource.GspRecordNo" readonly disabled></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-form-item>
                                <el-form-item>
                                    <el-col :span="3" class="form-title"><span style="color:red;">*</span>货主编号：</el-col>
                                    <el-col :span="9">
                                        <el-form-item prop="CustomerCode">
                                            <LookPopup v-model="dataSource.CustomerCode"
                                                dataUrl='omsapi/customerinfo/getlist'
                                                syncField='CustomerCode'
                                                @syncFieldEvent="syncCustomerEvent"
                                                rules="basicValidate.CustomerCode"
                                            ></LookPopup>
                                            <!-- <el-input v-model.trim="dataSource.CustomerNo"
                                                suffix-icon="el-icon-search" readonly
                                            ></el-input> -->
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="3" class="form-title">货主名称：</el-col>
                                    <el-col :span="9">
                                        <el-form-item>
                                            <el-input v-model.trim="dataSource.CustomerName"
                                                readonly disabled
                                            ></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-form-item>
                                <el-form-item>
                                    <el-col :span="3" class="form-title"><span style="color:red;">*</span>客商编号：</el-col>
                                    <el-col :span="9">
                                        <el-form-item prop="VendorCode">
                                            <LookPopup v-model="dataSource.VendorCode"
                                                dataTitle="客商选择"
                                                dataUrl='omsapi/customervendor/getlist'
                                                syncField='VendorCode'
                                                @syncFieldEvent="syncVendorEvent"
                                                rules="basicValidate.VendorCode"
                                            ></LookPopup>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="3" class="form-title">客商名称：</el-col>
                                    <el-col :span="9">
                                        <el-form-item>
                                            <el-input v-model.trim="dataSource.VendorName"
                                                readonly disabled
                                            ></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-form-item>
                                <el-form-item>
                                    <el-col :span="3" class="form-title"><span style="color:red;">*</span>商品编码：</el-col>
                                    <el-col :span="9">
                                        <el-form-item prop="ProductNo">
                                            <LookPopup v-model="dataSource.ProductNo"
                                                dataTitle="商品选择"
                                                dataUrl='omsapi/product/getproductlist'
                                                syncField='ProductNo'
                                                @syncFieldEvent="syncProductEvent"
                                                rules="basicValidate.ProductNo"
                                            ></LookPopup>
                                            <!-- <el-input v-model.trim="dataSource.ProductNo" 
                                                suffix-icon="el-icon-search" readonly
                                                ></el-input> -->
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="3" class="form-title">商品中文名称：</el-col>
                                    <el-col :span="9">
                                        <el-form-item>
                                            <el-input v-model.trim="dataSource.ProductName" readonly disabled></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-form-item>
                                <el-form-item>
                                    <el-col :span="3" class="form-title">规格：</el-col>
                                    <el-col :span="9">
                                        <el-form-item>
                                            <el-input v-model.trim="dataSource.ProductSpec"></el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="3" class="form-title">生成厂家：</el-col>
                                    <el-col :span="9">
                                        <el-form-item>
                                            <el-input v-model.trim="dataSource.ProductCompany"></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-form-item>
                                <el-form-item>
                                    <el-col :span="3" class="form-title">批准文号：</el-col>
                                    <el-col :span="9">
                                        <el-form-item prop="ProductApproveNum">
                                            <el-input v-model.trim="dataSource.ProductApproveNum"></el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="3" class="form-title">包装单位：</el-col>
                                    <el-col :span="9">
                                        <el-form-item prop="ProductUnit">
                                            <el-input v-model.trim="dataSource.ProductUnit"></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-form-item>
                                <el-form-item>
                                    <el-col :span="3" class="form-title">有效期至：</el-col>
                                    <el-col :span="9">
                                        <el-form-item prop="ProductExpiryDate">
                                            <el-date-picker v-model.trim="dataSource.ProductExpiryDate" placeholder="选择日期"
                                                type="datetime" value-format="yyyy-MM-dd HH:mm:ss">
                                            </el-date-picker>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="3" class="form-title">生成日期：</el-col>
                                    <el-col :span="9">
                                        <el-form-item>
                                            <el-date-picker v-model.trim="dataSource.ProductProduceDate" placeholder="选择日期"
                                                type="datetime" value-format="yyyy-MM-dd HH:mm:ss">
                                            </el-date-picker>
                                        </el-form-item>
                                    </el-col>
                                </el-form-item>
                                <el-form-item>
                                    <el-col :span="3" class="form-title">停售方式：</el-col>
                                    <el-col :span="9">
                                        <el-form-item>
                                            <DictionarySelect v-model="dataSource.StopType" datType='Int' dicTypeCode="ProductStopTypeCode" @change="stopTypeChange">
                                            </DictionarySelect>
                                        </el-form-item>
                                    </el-col>
                                    <template v-if="stopType==200">
                                        <el-col :span="3" class="form-title">批次：</el-col>
                                        <el-col :span="9" >
                                            <el-form-item>
                                                <el-input v-model.trim="dataSource.ProductBatch"></el-input>
                                            </el-form-item>
                                        </el-col>
                                    </template>
                                    <template v-else-if="stopType==300">
                                        <el-col :span="3" class="form-title">批号：</el-col>
                                        <el-col :span="9">
                                            <el-form-item>
                                                <el-input v-model.trim="dataSource.ProductBatchCode"></el-input>
                                            </el-form-item>
                                        </el-col>
                                    </template>
                                    
                                </el-form-item>
                                <el-form-item>
                                    <el-col :span="3" class="form-title">涉及数量：</el-col>
                                    <el-col :span="9">
                                        <el-form-item>
                                            <el-input v-model.trim="dataSource.StopNum"></el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="3" class="form-title">后续处理方式：</el-col>
                                    <el-col :span="9" prop="StopHandleType">
                                        <el-form-item>
                                            <DictionarySelect v-model="dataSource.StopHandleType" datType='Int' dicTypeCode="ProductStopHandleTypeCode">
                                            </DictionarySelect>
                                        </el-form-item>
                                    </el-col>
                                </el-form-item>
                                <el-form-item>
                                    <el-col :span="3" class="form-title">停售原因：</el-col>
                                    <el-col :span="9">
                                        <el-form-item prop="StopReasonType">
                                            <DictionarySelect v-model="dataSource.StopReasonType" datType='Int' dicTypeCode="ProductStopReasonTypeCode">
                                            </DictionarySelect>
                                        </el-form-item>
                                    </el-col>
                                </el-form-item>
                                <el-form-item>
                                    <el-col :span="3" class="form-title">备注：</el-col>
                                    <el-col :span="21">
                                        <el-form-item>
                                            <el-input type="textarea" 
                                                v-model.trim="dataSource.StopRemark"
                                                rows="4"
                                                :maxlength="200">
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-form-item>
                            </el-collapse-item>
                        </el-collapse>
                    </el-tab-pane>
                </el-tabs>
            </el-form> 
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                collapseItem: ['basicinfo','basicinfo1','basicinfo2','basicinfo3'],
                basicValidate: {
                    CustomerCode:[{ required: true, message: '货主不能为空', trigger: 'blur' }],
                    VendorCode:[{ required: true, message: '客商不能为空', trigger: 'blur' }],
                    ProductNo:[{ required: true, message: '商品不能为空', trigger: 'blur' }],
                    ProductBatchCode:[{ required: true, message: '批号不能为空', trigger: 'blur' }],
                    ProductNum:[{ required: true, message: '数量不能为空', trigger: 'blur' }],
                    ComplaintContent:[{ required: true, message: '质量问题不能为空', trigger: 'blur' }],
                },
                activeName:"tabbasic",
                dataSource:{
                },
                processStatusList:[],
                stopType:null,
            }
        },
        props: {
            config: {
                isDetailDisplay: false,
                isAdd:false
            },
        },
        mounted: function () {
        },
        computed: {
        },
        watch: {
            'config.dataSourceKey':{
                handler(curVal, oldVal) {
                    if(curVal&&curVal>0){
                        if(curVal!=oldVal) 
                            this.initial(curVal); //初始化
                    }else{
                        this.add(); //新增初始化
                    }
                },
                deep: true
            }
        },
        methods: {
            //重置表单
            resetForm: function () {
                this.Utils.clearForm(this.dataSource);
            },
            back: function () {
                this.config.isDetailDisplay = false;
            },
            add: function () {
                this.initial();
                this.config.isAdd = true;
            },
            initial(id){
                var _this = this;
                _this.$ajax.send("omsapi/gspdrugstopsale/get", "get", { id: id }, (data) => {
                    _this.dataSource=data.Result;
                    _this.$refs["basicForm"].clearValidate();
                });
            },
            save: function () {
                var _this = this;
                _this.$refs["basicForm"].validate((valid) => {
                    if (valid) {
                        _this.$ajax.send(
                            'omsapi/gspdrugstopsale/save',
                            'post',
                            _this.dataSource,
                            (data) => {
                                _this.dataSource = data.Result;
                                _this.Event.$emit('reloadPageList', data.Result)
                                _this.Utils.messageBox('保存成功.', 'success')
                            }
                        )
                    } else {
                        return false
                    }
                });
            },
            syncVendorEvent:function(row){
                if(row){
                    this.dataSource.VendorName = row.VendorName;
                }
            },
            syncCustomerEvent:function(row){
                if(row){
                    this.dataSource.CustomerName = row.CustomerName;
                }
            },
            syncProductEvent:function(row){
                if(row){
                    this.dataSource.ProductName = row.ProductName;
                }
            },
            stopTypeChange:function(val){
                this.stopType = parseInt(val);
            }
        },
        components: {
        }
    }
</script>